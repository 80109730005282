var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            { staticStyle: { "max-width": "1600px" }, attrs: { fluid: "" } },
            [
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(" Search "),
                        _c("span", { staticStyle: { "padding-left": "2px" } }, [
                          _vm._v("(" + _vm._s(_vm.totalRecord) + ")"),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { type: "text", label: "Name" },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllQuotes()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchParam, "name", $$v)
                                      },
                                      expression: "searchParam.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.equipment,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Equipment Type",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchParam.equipmentFilter,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "equipmentFilter",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.equipmentFilter",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "text",
                                      label: "DOT Number",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllQuotes()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.dotNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "dotNumber",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.dotNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { type: "text", label: "MC Number" },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllQuotes()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.mcNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "mcNumber",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.mcNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "text",
                                      label: "Load Number",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllQuotes()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.loadId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchParam, "loadId", $$v)
                                      },
                                      expression: "searchParam.loadId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "text",
                                      label: "Origin City",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllQuotes()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.originCity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "originCity",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.originCity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "text",
                                      label: "Origin State",
                                      hint: "Short state name (Ex. Texas - TX)",
                                      "persistent-hint": "",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllQuotes()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.originState,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "originState",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.originState",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "text",
                                      label: "Destination City",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllQuotes()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.destinationCity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "destinationCity",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.destinationCity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "text",
                                      label: "Destination State",
                                      hint: "Short state name (Ex. Texas - TX)",
                                      "persistent-hint": "",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllQuotes()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.destinationState,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "destinationState",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchParam.destinationState",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "blue white--text dark-3",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.searchAllQuotes()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-search-web "),
                                      ]),
                                      _vm._v(" Filter "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetSearch()
                                        },
                                      },
                                    },
                                    [_vm._v(" Reset ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            {
              staticClass: "pt-0 data-container",
              attrs: { id: "tblQuotes", fluid: "" },
            },
            [
              _c("v-data-table", {
                staticClass: "elevation-2 data-table",
                attrs: {
                  id: "tblData",
                  headers: _vm.headers,
                  items: _vm.quotes,
                  "calculate-widths": "",
                  "hide-default-footer": "",
                  loading: _vm.loadingIcon,
                  "fixed-header": "",
                  "items-per-page": _vm.pageSize,
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.load",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              small: "",
                              target: "_blank",
                              to: {
                                name: "Load Detail",
                                params: { id: item.load.id },
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.load.id) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.shipper",
                    fn: function ({ item }) {
                      return [
                        item.shipper
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatLocation(
                                    item.shipper.city,
                                    item.shipper.state_province_region
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.consignee",
                    fn: function ({ item }) {
                      return [
                        item.consignee
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatLocation(
                                    item.consignee.city,
                                    item.consignee.state_province_region
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.name",
                    fn: function ({ item }) {
                      return [
                        item.carrierCompany
                          ? _c(
                              "router-link",
                              {
                                staticClass: "name-link",
                                attrs: {
                                  to: {
                                    name: "Carrier Detail",
                                    params: { id: item.carrierCompany },
                                  },
                                  target: "_blank",
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(item.name))])]
                            )
                          : _c(
                              "span",
                              [
                                _vm._v(_vm._s(item.name) + " "),
                                _c(
                                  "v-icon",
                                  { attrs: { "x-small": "", color: "red" } },
                                  [_vm._v("mdi-bus-alert")]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("v-page", {
                          staticClass: "pt-3 pr-3 pb-3",
                          attrs: {
                            "total-row": _vm.totalRecord,
                            "page-size-menu": [10, 20, 50, 100],
                          },
                          on: { "page-change": _vm.readDataFromAPI },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }